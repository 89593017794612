import { OptionProps, SingleOptionProps } from '@components/Form/types';
import shippingTaxRates from 'constants/shippingTaxRates';
import { DeliveryResponse, DropPoint } from '../pages/api/delivery.types';
import {
  Courier,
  DeliveryTypeCode,
  ShippingCountry,
  ShippingMethod,
  SiteLocale,
  TransportName,
} from '../types';
import { getDeliveryDropPoints } from './api/delivery';
import {
  calcDeliveryDay,
  calcDeliveryDays,
  earliestPossibleShippingDate,
  formatNiceDate,
  getDay,
  SHIPPING_DATE_FORMAT,
} from './utils.dates';
import { WizardFormValues } from '@components/forms/Recommendation/types';

export const shippingCountries = Object.values(ShippingCountry);

export const isBudbeeHomeAvailable = async (
  shippingCountry: ShippingCountry,
  postcode: string,
) => {
  const res = await fetch(
    `/api/postcode/?type=Budbee&shippingCountry=${shippingCountry}&postcode=${postcode}`,
  );

  const { available } = await res.json();

  return available as boolean;
};

// There are postcode where Budbee home delivery is available but post/drop point delivery is not. For example 20100
export const isBudbeePostAvailable = async (
  shippingCountry: ShippingCountry,
  postcode: string,
) => {
  const dropPointLocationsResponse: DeliveryResponse =
    await getDeliveryDropPoints({
      country: shippingCountry,
      address1: '',
      postcode,
      city: '',
      transportName: 'budbee',
    });

  return dropPointLocationsResponse.locations.length >= 1;
};

export const isBudbeeAvailable = async (
  shippingCountry: ShippingCountry,
  postcode: string,
  shippingMethod: ShippingMethod,
): Promise<boolean> => {
  if (shippingMethod === 'post') {
    return isBudbeePostAvailable(shippingCountry, postcode);
  }

  return isBudbeeHomeAvailable(shippingCountry, postcode);
};

export const isInstaboxAvailable = async (
  shippingCountry: ShippingCountry,
  postcode: string,
  shippingMethod: ShippingMethod,
): Promise<boolean> => {
  const dropPointLocationsResponse: DeliveryResponse =
    await getDeliveryDropPoints({
      country: shippingCountry,
      address1: '',
      postcode,
      city: '',
      transportName: 'instabox',
    });

  if (shippingMethod === 'post') {
    return dropPointLocationsResponse.locations.length >= 1;
  } else {
    return dropPointLocationsResponse.homeAvailable;
  }
};

export function getShippingMethodFromDeliveryType(
  deliveryTypeCode: DeliveryTypeCode,
): ShippingMethod {
  if (!deliveryTypeCode) {
    return null;
  }
  if (deliveryTypeCode.endsWith('1')) {
    return 'home';
  }
  if (deliveryTypeCode.endsWith('2')) {
    return 'post';
  }
  return null;
}

export function getShipmentTransportNameFromDeliveryType(
  deliveryTypeCode: DeliveryTypeCode,
): TransportName {
  if (!deliveryTypeCode) {
    return null;
  }
  if (deliveryTypeCode.startsWith('1')) {
    return Courier.posti;
  }
  if (deliveryTypeCode.startsWith('2')) {
    return Courier.budbee;
  }
  if (deliveryTypeCode.startsWith('5')) {
    return Courier.postnord;
  }
  if (deliveryTypeCode.startsWith('6')) {
    return Courier.matkahuolto;
  }
  if (deliveryTypeCode.startsWith('7')) {
    return Courier.bring;
  }
  return null;
}

interface DeliveryInfo {
  billing_postcode: string;
  billing_city: string;
  billing_address_1: string;
  billing_country?: string;
  shipping_postcode?: string;
  shipping_city?: string;
  shipping_address_1?: string;
  shipping_country?: ShippingCountry;
  delivery_type?: DeliveryTypeCode;
  delivery_name?: string;
  delivery_drop_point_id?: string;
  delivery_method?: ShippingMethod;
  pickup_point_address?: string;
  pickup_point_city?: string;
  pickup_point_postcode?: string;
  pickup_point_country?: string;
  phone: string;
}

export type GetDeliveryInfoParams = Pick<
  WizardFormValues,
  | 'shipping_address_1'
  | 'shipping_country'
  | 'shipping_city'
  | 'shipping_postcode'
  | 'shipment_transport_name'
  | 'shipping_method'
  | 'delivery_drop_point'
  | 'countryCode'
  | 'phone'
>;

export const getDeliveryInfo = async ({
  shipping_address_1: address1,
  shipping_country: country,
  shipping_city: city,
  shipping_postcode: postcode,
  shipment_transport_name: transportName,
  shipping_method: shippingMethod,
  delivery_drop_point: deliveryDropPoint,
  countryCode,
  phone,
}: GetDeliveryInfoParams): Promise<DeliveryInfo | null> => {
  let delivery_name = '';
  let delivery_type: DeliveryTypeCode;
  let delivery_drop_point_id = '';

  let pickup_point_address;
  let pickup_point_city;
  let pickup_point_postcode;
  let pickup_point_country;

  if (shippingMethod === 'post' && postcode && transportName) {
    if (!deliveryDropPoint) {
      return null;
    }

    const dropPointLocationsResponse: DeliveryResponse =
      await getDeliveryDropPoints({
        country,
        address1,
        postcode,
        city,
        transportName,
      });

    const dropPointLocations =
      dropPointLocationsResponse.locations as DropPoint[];

    const dropPointLocation = dropPointLocations.find(
      (office) => office.id === deliveryDropPoint,
    );

    if (!dropPointLocation) {
      return null;
    }

    delivery_type = '202';
    delivery_drop_point_id = deliveryDropPoint;

    delivery_name = dropPointLocation.type;
    pickup_point_address = dropPointLocation?.address?.address;
    pickup_point_postcode = dropPointLocation?.address?.postcode;
    pickup_point_city = dropPointLocation?.address?.city;
    pickup_point_country = dropPointLocation?.address?.country;

    if (transportName === 'posti') {
      delivery_type = '102';
    } else if (transportName === 'budbee') {
      delivery_type = '202';
      delivery_name = 'BUDBEE BOX';
    } else if (transportName === 'postnord') {
      delivery_type = '502';
      delivery_name = 'PostNord';
    } else if (transportName === 'matkahuolto') {
      delivery_type = '602';
    } else if (transportName === 'bring') {
      delivery_type = '702';
      delivery_name = 'Bring Parcel';
    } else if (transportName === 'instabox') {
      delivery_type = '802';
      delivery_name = 'Instabox';
    }
  }

  if (shippingMethod === 'home') {
    if (transportName === 'posti') {
      delivery_type = '101';
      delivery_name = 'POSTI';
    }

    if (transportName === 'budbee') {
      delivery_type = '201';
      delivery_name = 'BUDBEE';
    }

    if (transportName === 'postnord') {
      delivery_type = '501';
      delivery_name = 'PostNord';
    }

    if (transportName === 'postnord') {
      delivery_type = '501';
      delivery_name = 'PostNord';
    }

    if (transportName === 'matkahuolto') {
      delivery_type = '601';
      delivery_name = 'Matkahuolto';
    }

    if (transportName === 'bring') {
      delivery_type = '701';
      delivery_name = 'Bring';
    }

    if (transportName === 'instabox') {
      delivery_type = '801';
      delivery_name = 'Instabox';
    }
  }

  if (countryCode) {
    phone = countryCode + phone;
  }

  return {
    billing_postcode: postcode,
    billing_city: city,
    billing_address_1: address1,
    billing_country: country,
    shipping_postcode: postcode,
    shipping_city: city,
    shipping_address_1: address1,
    shipping_country: country,
    delivery_name,
    delivery_type,
    delivery_drop_point_id,
    delivery_method: shippingMethod,
    phone,
    pickup_point_address,
    pickup_point_city,
    pickup_point_postcode,
    pickup_point_country,
  };
};

export const getTransportNames = (country: ShippingCountry): string[] =>
  ({
    [ShippingCountry.SE]: [
      Courier.budbee,
      Courier.instabox,
      Courier.bring,
      Courier.postnord,
    ],
    [ShippingCountry.DK]: [
      Courier.budbee,
      Courier.instabox,
      Courier.bring,
      Courier.postnord,
    ],
    [ShippingCountry.FI]: [Courier.budbee, Courier.matkahuolto, Courier.posti],
  })[country];

export const addShippingTax = (preTax = 0, country: ShippingCountry): string =>
  (preTax * (1 + shippingTaxRates[country])).toFixed(2);

export const getShippingDate = (
  next_payment_date: string | false,
): string | false => {
  if (!next_payment_date) {
    return false;
  }
  const date = getDay(next_payment_date);

  return (
    date.isBefore(earliestPossibleShippingDate)
      ? earliestPossibleShippingDate
      : date
  ).format(SHIPPING_DATE_FORMAT);
};

export const getDeliveryDayOptions = (
  shippingDate: string | false,
  locale: SiteLocale,
): OptionProps => {
  let deliveryDayOptions: OptionProps = calcDeliveryDays().map(
    ({ shipping, delivery }) => ({
      value: shipping.format(SHIPPING_DATE_FORMAT),
      node: formatNiceDate(delivery, locale),
    }),
  );

  // Add current selected shipping date, if not exists already
  if (
    shippingDate &&
    !deliveryDayOptions.find(
      (option) =>
        option.value === getDay(shippingDate).format(SHIPPING_DATE_FORMAT),
    )
  ) {
    deliveryDayOptions.push({
      value: getDay(shippingDate).format(SHIPPING_DATE_FORMAT),
      node: formatNiceDate(calcDeliveryDay(getDay(shippingDate)), locale),
    });

    deliveryDayOptions = deliveryDayOptions.sort(
      (a: SingleOptionProps, b: SingleOptionProps) => {
        if (a.value && b.value && a.value > b.value) {
          return 1;
        }
        return -1;
      },
    );
  }
  return deliveryDayOptions;
};

export const getPaymentDayOptions = (
  paymentDate: string | false,
  locale: SiteLocale,
): OptionProps => {
  let paymentDayOptions: OptionProps = calcDeliveryDays().map(
    ({ payment }) => ({
      value: payment.format(SHIPPING_DATE_FORMAT),
      node: formatNiceDate(payment, locale),
    }),
  );

  if (paymentDate) {
    const currentPaymentDate = getDay(paymentDate).format(SHIPPING_DATE_FORMAT);
    const alreadyAdded = paymentDayOptions.find(
      ({ value }) => value === currentPaymentDate,
    );

    if (!alreadyAdded) {
      paymentDayOptions.push({
        value: currentPaymentDate,
        node: formatNiceDate(getDay(paymentDate), locale, false),
      });
    }
  }

  return (paymentDayOptions = paymentDayOptions.sort(
    (a: SingleOptionProps, b: SingleOptionProps) => {
      if (a.value && b.value && a.value > b.value) {
        return 1;
      }
      return -1;
    },
  ));
};
